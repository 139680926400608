<template>
    <v-snackbar
      v-model="$store.state.app.snackBar.display"
      :timeout="10000"
    >
      {{ $store.state.app.snackBar.text.text ||  $store.state.app.snackBar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="clickHandler"
          class=""
        >
          {{ $store.state.app.snackBar.text.button || 'Close' }}
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  methods: {
    clickHandler(){
      if(this.$store.state.app.snackBar.text.action) this.$store.state.app.snackBar.text.action()
      this.$store.state.app.snackBar.display = false
    }
  }
}
</script>